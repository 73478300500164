<template>
  <div>
    <b-button
      v-if="checkPermission('add wallet transaction')"
      v-b-modal.form-user-modal
      class="btn btn-info btn-sm mb-1"
      data-toggle="tooltip"
      data-placement="top"
      title="Add User"
      @click="cleanUpForm()"
    >
      <feather-icon
        icon="PlusCircleIcon"
      />
      {{ title }}
    </b-button>

    <b-modal
      id="form-user-modal"
      no-close-on-backdrop
      size="lg"
      title="Add Wallet"
    >
      <div class="form">
        <h5>{{ title }}</h5>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label
                class="text-capitalize"
                for=""
              >Select {{ typeUserOrTalents }}</label>
              <validation-provider>
                <vue-autosuggest
                  :suggestions="userData"
                  :input-props="inputProps"
                  :get-suggestion-value="getSuggestionValue"
                  :limit="10"
                  :state="validations.user_uuid ? false:null"
                  :class="validations.user_uuid ? 'border-danger' : ''"
                  @input="onInputChange"
                >
                  <template slot-scope="{suggestion}">
                    <div class="d-flex align-items-center">
                      <b-avatar :src="suggestion.item.photo_url" />
                      <div class="detail ml-50">
                        <b-card-text class="mb-0">
                          {{ suggestion.item.name }}
                        </b-card-text>
                        <small class="text-muted">
                          {{ suggestion.item.email }}
                        </small>
                      </div>
                    </div>
                  </template>
                </vue-autosuggest>
                <small
                  v-for="(validation, index) in validations.user_uuid"
                  v-if="validations.user_uuid"
                  :key="`errorId${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Type:</label>
              <validation-provider>
                <select
                  v-model="formPayload.transaction_type"
                  class="form-control"
                  :class="validations.transaction_type ? 'border-danger' : ''"
                  :state="validations.transaction_type ? false:null"
                >
                  <option value="">
                    --- Choose Type Transaction ---
                  </option>
                  <option value="add">
                    Add
                  </option>
                  <option value="deduct">
                    Deduct
                  </option>
                </select>
                <small
                  v-for="(validation, index) in validations.transaction_type"
                  v-if="validations.transaction_type"
                  :key="`errorAmount${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Amount:</label>
              <validation-provider>
                <b-form-input
                  v-model="formPayload.amount"
                  :state="validations.name ? false:null"
                  placeholder="Amount"
                />
                <small
                  v-for="(validation, index) in validations.amount"
                  v-if="validations.amount"
                  :key="`errorAmount${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <label for="">Note:</label>
              <validation-provider>
                <b-form-input
                  v-model="formPayload.note"
                  :state="validations.note ? false:null"
                  placeholder="Note"
                />
                <small
                  v-for="(validation, index) in validations.note"
                  v-if="validations.note"
                  :key="`errornote${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button
          variant="success"
          :disabled="isLoading"
          @click="createItem()"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BSpinner, BFormInput, BAvatar, BCardText,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BButton,
    BSpinner,
    ValidationProvider,
    BFormInput,
    VueAutosuggest,
    BAvatar,
    BCardText,
  },
  props: ['result', 'typeSlug', 'title', 'typeUserOrTalents'],
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoading: false,
      formPayload: Object,
      validations: '',
      datasuggest: [],
      userData: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: `Select ${this.typeUserOrTalents} by search by name or email...`,
      },
      limit: 10,
      amountFormat: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }
  },
  methods: {
    getSuggestionValue(suggestion) {
      switch (this.typeUserOrTalents) {
        case 'talents':
          this.formPayload.talent_uuid = suggestion.item.uuid
          break
        default:
          this.formPayload.user_uuid = suggestion.item.uuid
          break
      }
      return suggestion.item.name
    },
    onSelected(option) {
      this.selected = option.item
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        this.userData = []
        return
      }

      if (text.length > 2) {
        this.$http.get(`/admin/${this.typeUserOrTalents}`, {
          params: {
            user_type: 'normal',
            keyword: text,
          },
        })
          .then(response => {
            this.userData = [{
              data: response.data.data.data,
            }]
          })
      }
    },
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        transaction_type: '',
        amount: '',
        note: '',
      }
      this.validations = ''
    },
    createItem() {
      const form = this.preparePayload()
      this.isLoading = true

      this.$http.post(`/admin/wallet/${this.typeSlug}/add-transaction`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-user-modal')
          // Trigger Function from parent component
          this.$emit('getDataChild')
          successNotification(this, 'Success', 'Transaction successfully created')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
          }
          this.isLoading = false
        })
    },
    preparePayload() {
      const form = new FormData()
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      return form
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
[dir] .autosuggest__results-container .autosuggest__results {
    background-color: #fff;
    margin-top: 1rem;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
}
.autosuggest__results-container .autosuggest__results {
  width: 100%;
  overflow-y: auto;
  max-height: 40vh;
}
[dir=ltr] .autosuggest__results-container .autosuggest__results ul {
  padding-left: 0;
}
[dir] .autosuggest__results-container .autosuggest__results ul {
  margin-bottom: 0;
}
[dir] .autosuggest__results-container .autosuggest__results ul li {
    padding: 0.75rem 1rem;
}
.autosuggest__results-container .autosuggest__results ul li {
  list-style: none;
}

[dir] .autosuggest__results-container .autosuggest__results ul li.autosuggest__results-item--highlighted, [dir] .autosuggest__results-container .autosuggest__results ul li:hover {
  background: #ededed;
}
</style>
