<template>
  <div>
    <div class="dt_adv_search ml-1 mr-1" v-if="searchFilter == true">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-row">
            <div class="col-lg-12">
              <div class="form-row mb-1 justify-content-between">
                <div class="col-lg-4 mb-1 mb-lg-0">
                  <label>Filter by {{ typeUserOrTalents }}</label>
                  <v-select
                    v-model="filter.user_uuid"
                    :options="users"
                    :reduce="option => option.uuid"
                    @search="onSearchUser"
                  >
                    <template slot="no-options">
                      Type {{ typeUserOrTalents }} name..
                    </template>
                  </v-select>
                </div>
                <div class="col-lg-4 mb-1 mb-lg-0 align-self-end justify-content-end">
                  <input
                    v-model="filter.keyword"
                    type="text"
                    placeholder="Search"
                    class="form-control form-control-sm float-right"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Name</th>
            <th>Time</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoadingComponent">
            <td
              colspan="6"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in resultData.data"
            v-else
            :key="item.id"
          >
            <td>
              <router-link :to="typeUserOrTalents == 'talents' ? `/talenta-detail/${item.holder.uuid}` : `/user-detail/${item.holder.uuid}`">
                {{ item.holder.name }}
              </router-link>
            </td>
            <td>{{ item.time | formatDateRegister }}</td>
            <td><span class="text-capitalize">{{ item.type }}</span></td>
            <td :class="item.amount < 0 ? 'text-danger' : 'text-success'">{{ item.amount | formatAmount }}</td>
            <td>{{ item.description }}</td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="resultData.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="resultData"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import vSelect from 'vue-select'
import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    vSelect,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    typeSlug: {
      type: String,
    },
    searchFilter: {
      type: Boolean,
    },
    userUuid: {
      type: String,
    },
    result: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    value: {
      type: String,
    },
    getData: {
      type: Function,
    },
    typeUserOrTalents: {
      type: String,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value
    },
    result(value) {
      this.resultData = value
    },
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoadingComponent: true,
      users: [],
      resultData: {},
      filter: {
        user_uuid: '',
        keyword: '',
      },
    }
  },
  methods: {
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true)
        this.searchUser(loading, search, this)
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.$http.get(`/admin/${vm.typeUserOrTalents}?type=normal&per_page=10&keyword=${escape(search)}`)
        .then(response => {
          vm.users = []
          response.data.data.data.forEach(user => {
            vm.users.push({
              uuid: user.uuid,
              label: user.name,
            })
          })
          loading(false)
        })
    }, 300),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
