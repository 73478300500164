<template>
  <b-card-code
    no-body
    title="Pital Coin"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-md-12">
          <AddWalletTransaction
            :type-slug="'coin'"
            :title="'Add/Deduct Pital Coin'"
            :type-user-or-talents="'users'"
            @getDataChild="getData"
          />
        </div>
      </div>
    </div>
    <TransactionHistories
      :type-slug="'coin'"
      :result="result"
      :is-loading="isLoading"
      :search-filter="true"
      :type-user-or-talents="'users'"
      :get-data="getData"
      @filter="getFilter($event)"
    />
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import AddWalletTransaction from '@/components/Wallet/AddWalletTransaction.vue'
import TransactionHistories from '@/components/Wallet/TransactionHistories.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return `Transaction Pital Coin`
  },
  components: {
    BCardCode,
    AddWalletTransaction,
    TransactionHistories,
  },
  watch: {
    filter: {
      handler: _.debounce(function (value) {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        user_uuid: '',
        keyword: '',
      },
      isLoading: false,
      formPayload: Object,
      validations: '',
      editId: null,
      users: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getFilter(value) {
      this.filter = value || ''
    },
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true)
        this.searchUser(loading, search, this)
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.$http.get(`/admin/users?type=normal&per_page=10&keyword=${escape(search)}`)
        .then(response => {
          vm.users = []
          response.data.data.data.forEach(user => {
            vm.users.push({
              uuid: user.uuid,
              label: user.name,
            })
          })
          loading(false)
        })
    }, 300),
    getData() {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = this.currentPage

      this.$http.get('/admin/wallet/coin', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.currentPage = response.data.data.current_page
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
